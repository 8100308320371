#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
    overflow-x: hidden;
}

/* Header */
#backButtonMS {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleMS {
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_MS {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerMS {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-bottom: 10px;
}

#img_strain {
    width: 80px;
    height: 80px;
    aspect-ratio: 1;
    object-fit: cover;
}

#manageStrains {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    text-align: left;
    display: flex;
}
#word_ms {
    font-size: 16px;
    margin-left: 10px;
    margin-top: 2px;
}
#word1_ms {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}
#word2_ms  {
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
    word-wrap: break-word;
}
.mode_ms {
    display: grid;
    margin: 10px 10px 5px 10px;
    padding-bottom: 10px;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;

    animation: backwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) backwards;
}
#col1_ms {
    margin: 10px 10px 5px 10px;
}
#col2_ms {
    margin: 10px 10px 5px 0px;
    /* width: 50%; */
    /* outline: auto; */
}
#col3_ms {
    /* margin: 10px 10px 5px 0px; */
    display:flex;
    /* outline: auto;   */
    /* right: 20px; */
    position: absolute;
    right: 10px;
}
#col4_ms {
    margin: 10px 10px 5px 70px;
    display: flex;
}

#icon1_ms { 
    /* Edit */
    display: flex;
    margin-left: -5px;
    margin-top: 5px;
}
.icon2_ms {
    /* Active */
    display: flex;
    margin-left: 0px;
    margin-top: 4px;
}
.icon3_ms {
    /* Inactive */
   display: flex;
   margin-top: 12px;
   padding-right: 8px;
}
#icon4_ms {
    position: absolute;
    position: sticky;
    bottom: 0;
    right: 5vw;
}
label {
    display: flex;
    align-items: center;
}
#row1_ms {
    display: flex;
    position: relative;
}
#row2_ms {
    display: flex;
} 
#backButton_ms {
    margin-left: 10px;
}
#plus_ms {
    background-color: white;
    position: fixed;
    right: 10%;
    bottom: 10%; 
    width: 10%;
    height: 5%; 
    border-radius: 200px; 
}
#plus_button_ms {
    object-fit: cover;
    width: 200%;
    margin-left: -50%; 
    margin-top: -50%;
}

#statusPopup {
    position: absolute;
    bottom: 0;
    left: 50%;
}
.popup-content {
    box-shadow: 0px 0px 5px grey;
    border-radius: 2px;
    padding: 1%;
    background-color: white;

    animation: forwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

/* animation */
@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0px 0px 5px grey;
    }
}