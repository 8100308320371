#playStoreImage {
    position: relative;
    height: 20vw;
    width: 60vw;
    margin-bottom: 30vw;
}

#appleImage {
    position: relative;
    height: 30vw;
    width: 30vw;
    margin-bottom: 20vw;
}