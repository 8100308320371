#addStrain {
    padding-block: 10px;
    margin-left: 10px;
    text-align: left;
    display: flex;
    background-color: white;
}
#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow-x: hidden;
    overflow: scroll;
}
#backButtonES {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleES {
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_ES {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerES {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-bottom: 10px;
}
#picGroup_es {
    display: flex;
    margin-left: 5%;
    width: 100%;
    height: 10%;

}
#plus_es {
    /* outline: auto; */
    background-color:gray;
    width: 30%;
    height: 100%;
    border-radius: 5px;
    position:relative;
    object-fit: cover;
    margin-right: 10px;
}
#picBox_es {
    background-color:gray;
    width: 30%;
    height: 100%;
    border-radius: 5px;
    position:relative;
    object-fit: cover;
}
#plus_button_es {
    width: 60%;
    margin-left: 20%;
}
#strainPic_es{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#word {
    font-size: 16px;
    margin-left: 10px;
}
#word1_es {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    text-align: left;
    padding-bottom: 20px;
}
#word2 {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 2px;
    margin-top: 10px;
    text-align: left;
    font-weight: bold;
}
#word3 {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
}
#word4 {
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}
#box {
    background-color: rgb(234, 234, 234);
    margin: 10px 10px 5px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-radius: 5px;
}
#box1 {
    background-color: rgb(182, 182, 182);
    border-radius: 5px;
    margin: 10px 10px 5px 10px;
    width: 160px;
    height: 80px;

}
#box2 {
    background-color: rgb(234, 234, 234);
    display: flex;
    margin: 10px 10px 10px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}
#box3 {
    background-color: rgb(234, 234, 234);
    border-radius: 5px;
    width: 80%;
}
#box4 {
    background-color: rgb(60, 60, 60);
    margin: 10px 10px 5px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 5px;
}
select {
    width: 100%;
    border: none;
    background-color: rgb(234, 234, 234);
  }
  .slider {
    -webkit-appearance: none; 
    appearance: none;
    width: 100%; 
    height: 15px; 
    border-radius: 5px; 
    background: rgb(234, 234, 234);; 
    outline: none; 
    opacity: 0.7; 
    -webkit-transition: .2s; 
    transition: opacity .2s;
  }
  .slider::-webkit-slider-runnable-track {
    background: black;
    height: 4px;
   }
   
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    background: black;
    cursor: pointer;
    margin-top: -5px;
  }
  input[type=text] {
    width: 90%;
    border: none;
    background-color: rgb(234, 234, 234);
    margin-left: 10px;
  }
  #icon3a{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
#price_as {
    background: none;
    border: 0px;
    margin-left: 10px;

}   