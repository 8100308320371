#contactUsScreen {
    position: relative;
    height: 100vh;
    background-color: #fafafa;
}

/* Header */
#backButtonContact {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleContact {
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_CU {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerContact {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
}


/* Input / Body */
#aboutContactUs {
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 2.5%;
    font-size: 1.2em;
    color: #707070;
    padding-bottom: 10px;
    margin-left: 5px;
}

#contactUsMessageContact {
    display: flex;
    justify-content: left;
}

#emailIDFieldContact {
    background-color: #eaeaea;
    width: 90%;
    height: 1.5em;
    border: none;
    border-radius: 7.5px;  
    font-size: 1.25em;
    margin-left: 4%;
    margin-bottom: 10px;
}

#feedbackMessageContact {
    background-color: #eaeaea;
    width: 90%;
    height: 7em;
    border: none;
    border-radius: 7.5px;
    font-size: 1.25em;
    margin-left: 4%;
    margin-bottom: 10px;
}

.inputEmailContact, .inputFeedbackContact {
    margin-left: 5px;
    margin-right: 2.5%;
    border-radius: 7.5px;
    padding-bottom: 1%;
    margin-bottom: 1%;
    padding-left: 2.5%;
    text-align: left;
    color: #1a1a1a;
    font-size: large;
}




/* Submit */
#submitButtonContact {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #323232;
    border-radius: 7.5px;
    background-color: #323232;
    margin-top: 4%;
    margin-left: 4%;
    margin-right: 4%;
    height: 50px;
    width: 92vw;
    text-decoration: none;
    color: white;
    font-size: 1.25em;
}
