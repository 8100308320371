#backArrowDiv {
    position: relative;
    width: 100px;
    height: undefined;
    aspect-ratio: 1/1;
}

#selectUserBackButton {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#selectUserBackPic {
    position: relative;
    width: 45px;
    height: 45px;
    aspect-ratio: 1/1;
}

#potLogo2 {
    position: relative;
    width: 100px;
    height: undefined;
    aspect-ratio: 1/1;
}

#selectUserHeader {
    position: absolute;
    font-size: 4.5vh;
    font-weight: bold;
    top: 23vh;
    left: 3vw;
    right: auto;
    margin-bottom: 5vh;
}

#sharerUserTypeBox {
    position: absolute;
    top: 33vh;
    left: 4vw;
    width: 42vw;
    height: 15vh;
    background-color: rgb(214, 214, 214);
    border-radius: 2vw;
}

#shareeUserTypeBox {
    position: absolute;
    top: 33vh;
    left: 54vw;
    width: 42vw;
    height: 15vh;
    background-color: rgb(214, 214, 214);
    border-radius: 2vw;
}

#selectUserSharerBoxLabel {
    position: absolute;
    top: 33.5vh;
    left: 5.5vw;
    right: 55.5vw;
}

#selectUserShareeBoxLabel {
    position: absolute;
    top: 33.5vh;
    left: 55.5vw;
    right: 5.5vw;
}

#selectedUserCountryBox {
    position: absolute;
    top: 48vh;
    left: 4vw;
    height: 7vh;
    width: 92vw;
    background-color: rgb(214, 214, 214);
    border-radius: 2vw;
    border: none;
    font-size: 2vh;
    color: black;
}

#selectUserStateBox {
    position: absolute;
    top: 58vh;
    left: 4vw;
    height: 7vh;
    width: 92vw;
    background-color: rgb(214, 214, 214);
    border-radius: 2vw;
    border: none;
    font-size: 2vh;
    color: black;
}

#selectUserNextButton {
    position: absolute;
    top: 75vh;
    left: 4vw;
    width: 92vw;
    height: 6vh;
    border-radius: 2vw;
    background-color: rgb(42, 42, 42);
    color: white;
    font-size: 6vw;
}

#selectCountryGroup {
    top: 49vh;
}

#selectStateGroup {
    top: 52vh;
}