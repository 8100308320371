.Sidebar {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    
  }
  
  .menu-bars {
    margin-left: 0rem;
    font-size: 2rem;
    color: rgb(50,50,50);
    position:absolute;
    top: 0;
    left: 0;
  }
  
  .nav-menu {
    background-color: #ffffff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    background-color: #eaeaea;
  }
  
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
    margin-top: 30%;
  }
  
  .navbar-toggle {
    background-color: #ffffff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }

  #navBarBox {
    position: relative;
    left: -6vw;
    right: auto;
    height: 80%;
  }
  .pfp {
    width: 10px;
    height: 18px;
  }

  .userName {
    position: absolute;
    left: 40px;
    top: 100px;
    color: #000000;
    font-size: large;
  }

  .userEmail {
    position: absolute;
    left: 40px;
    top: 125px;
    color: #5a5a5a;
    font-size: .69em;
  }
  .sbinfo {
    display: block;
    height: 50px;
  }
  #potLogo_sb {
    position: absolute;
    top: 25px;
    left: 40px;
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 5px;
  }