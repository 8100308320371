#phoneFrame {
    position: relative;
    height: 100vh;
    background-color: #fafafa;
}

/* Header */
#backButtonEP {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleEP {
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_EP {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerEP {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-bottom: 10px;
}

#previousButton {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitle {
    display: flex;
    padding-left: 2.5%;
    top: 50%;
    font-size: 1.25em;
}

#header {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
}




#picGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
}

#profilePic {
    
    border-radius: 5px;
    overflow: hidden;
    background-color: #fafafa;
    object-fit: cover;
}

#changePicText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25em;
    color: #444444;
    margin-top: 5px;
    /* text-underline-position: under; */
}

input[type="file"] {
    display: contents;
}




#typeText {
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 2.5%;
    font-size: 1.75em;
    color: #707070;
}

#typeGroup {
    display: flex;
    justify-content: left;
}




#editEmail, #editName, #editAge {
    background-color: #ececec;
    width: 95%;
    height:30px;
    border: none;
    border-radius: 2px;
    font-size: 1.25em;
    margin-left: 2%;
    padding: 2px;
    text-indent: 1%;
}

.inputField {
    margin-bottom: 1%;
    padding-left: 2.5%;
    text-align: left;
    color: #9f9f9f;
}

.inputFieldGroup {
    margin-left: 2.5%;
    margin-right: 2.5%;
    padding-bottom: 1%;
}




#updateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #323232;
    border-radius: 7.5px;
    background-color: #323232;
    margin-top: 4%;
    margin-left: 4%;
    margin-right: 4%;
    height: 50px;
    width: 92vw;
    text-decoration: none;
    color: white;
    font-size: 1.25em;
}