.map-responsive10{

    overflow:hidden;

    padding-bottom:1vh;

    position:relative;

    height:55vh;

}

.map-responsive10 iframe{
    left:0;
    top:0;
    height:55vh;
    width:100%;
    position:absolute;

}

#orderID {
    font-size: 2vh;
    position: relative;
    text-align: center;
    padding-bottom: 1vh;
    font-weight: bold;
}

#sharerOrderLocation {
    font-size: 2vh;
    position: relative;
    text-align: center;
    margin-left: 2vw;
    margin-right: 1vw;
}

#sharerOrderInstructions {
    font-size: 2vh;
    position: relative;
    text-align: center;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 1vh;
}

#sharerOrderName {
    font-size: 1.7vh;
    position: relative;
    text-align: left;
    padding-bottom: 2px;
}

#sharerOrderEmail {
    font-size: 1.7vh;
    position: relative;
    text-align: left;
    padding-bottom: 2px;
}

#sharerOrderNumber {
    font-size: 1.7vh;
    position: relative;
    text-align: left;
}

#markDelivered {
    color: white;
    background-color: #313030;
    width: 90vw;
    height: 6vh;
    position: relative;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 2.3vh;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 2.5vh;
    border: none;
    margin-top: 3vh;
}

/* #sharerOrderNavBar {
    position: absolute;
    top: 5vh;
    left: 3vw;
    right: auto;
} */
#sharerInfo {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
}
#sharer_col {
    margin-left: 10px;
}
#shareepfp {
    height: 90px;
    width: 90px;
    border-radius: 5px;
    object-fit: cover;
}