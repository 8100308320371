#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
}

#save {
    background-color: #323232;
    color: white;
    border: 0px solid blue;
    border-radius: 5px;
    bottom: 0;
    position: sticky;
    display: flex;
    height: 13.5vw;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    align-items: center;
    justify-content: center;
    width: 94vw;
    left: 2.25vw;
    font-size: large;
}

#header {
    position: sticky;
    top: 0;
    height: 15vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    background-color: #fafafa;
}

#header > button {
    border: 0px solid blue;
    background-color: inherit;
    color: blue;
    font-weight: bold;
    font-size: medium;
    transform: translateX(3vw);
}

#blurb, #review {
    margin: 3%;
}

#reviewText {
    margin: 2vw;
    padding-bottom: 20vw;
    background-color:gainsboro;
    height: 40%;
    width: 90%
}