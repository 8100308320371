#description1Container {
    position: relative;
    /* top: 25vw; */
}

#appDescription {
    text-align: left;
    color: black;
    font-weight: bold;
    font-size: 5vw;
    position: relative;
    margin-left: 5vw;
    margin-right: 5vw;
    /* border: 2px solid black; */
    border-left: 2vw;
    border-right: 2vw;
    
}

#descriptionButton1 {
    position: relative;
    border: none;
    border-bottom: 2px solid black;
    margin-bottom: 3vw;
    color: black;
    font-size: 6vw;
    font-weight: bold;
    text-align: left;
    background-color: white;
    height: 15vw;
    width: 90vw;
}

#appDescription2 {
    text-align: left;
    color: black;
    font-weight: bold;
    font-size: 5vw;
    position: relative;
    margin-left: 5vw;
    margin-right: 5vw;
    /* border: 3px solid black; */
    border-left: 2vw;
    border-right: 2vw;
    
}

#descriptionButton2 {
    position: relative;
    border: none;
    border-bottom: 2px solid black;
    color: black;
    margin-top: 3vw;
    font-size: 6vw;
    font-weight: bold;
    text-align: left;
    background-color: white;
    height: 15vw;
    width: 90vw;
}