.map-responsive20{

    overflow:hidden;

    padding-bottom:1vh;

    position:relative;

    height:55vh;

}

.map-responsive20 iframe{
    left:0;
    top:0;
    height:55vh;
    width:100%;
    position:absolute;

}

#shareeOrderID {
    font-size: 2vh;
    position: relative;
    text-align: center;
    padding-bottom: 1vh;
    font-weight: bold;
}

#shareeOrderLocation {
    font-size: 2vh;
    position: relative;
    text-align: center;
    margin-left: 2vw;
    margin-right: 2vw;
}

#shareeOrderInstructions {
    font-size: 2vh;
    position: relative;
    text-align: center;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 1vh;
    margin-bottom: 3vh;
}

#shareeOrderName {
    font-size: 2vh;
    position: relative;
    text-align: left;
    padding-bottom: 2px;
}

#shareeOrderEmail {
    font-size: 2vh;
    position: relative;
    text-align: left;
    padding-bottom: 2px;
}

#shareeOrderNumber {
    font-size: 2vh;
    position: relative;
    text-align: left;
}

#shareeSellerLocation {
    font-size: 2vh;
    position: relative;
    text-align: left;
    margin-left: 28vw;
    margin-top: 1vh;
}

/* #sharerOrderNavBar {
    position: absolute;
    top: 5vh;
    left: 3vw;
    right: auto;
} */
#shareeInfo {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
}
#sharee_col {
    margin-left: 10px;
}
#sharerpfp {
    height: 90px;
    width: 90px;
    border-radius: 5px;
    object-fit: cover;
}