#uploadBackDiv {
    position: relative;
    width: 100px;
}

#uploadPFPBackButton {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#uploadPFPBackPic {
    position: relative;
    width: 45px;
    height: 45px;
}

#pfpTitle {
    position: relative;
    text-align: center;
    top: 10vh;
    font-size: 4vh;
    font-weight: bold;
}

#selectPic {
    position: relative;
    top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
}

#firstProfilePic {
    border: 0px solid #048474;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fafafa;
    object-fit: cover;
}

#setPicText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25em;
    color: black;
    margin-top: 3vh;
}

input[type="file"] {
    display: contents;
}

#uploadPicError {
    position: relative;
    top: 17vh;
    color: red;
    margin-bottom: 0;
}

#uploadPicText {
    position: relative;
    top: 21vh;
    text-align: center;
    margin-left: 25vw;
    margin-right: 25vw;
    font-size: 2.5vh;
    font-style: italic;
    color: rgb(83, 83, 83);
}

#uploadPFPNextButton {
    position: absolute;
    top: 136vw;
    left: 5vw;
    right: auto;
    width: 90vw;
    height: 6vh;
    border-radius: 2vw;
    background-color: rgb(42, 42, 42);
    color: white;
    font-size: 2.8vh;
}
#pfpu {
    position: relative;
    top: 16vh;
    text-align: center;
    margin-left: 25vw;
    margin-right: 25vw;
    font-size: 2.5vh;
    color: rgb(83, 83, 83);
}