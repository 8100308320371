#chooseDelivery {
    width: 100%;
    padding-block: 10px;
    margin-left: 10px;
    text-align: left;
    display: flex;
    background-color: white;
}
#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
}
#bbutton {
    margin-top: 20px;
    margin-left: 5px;
}
#description {
    font-size: 10px;
    margin-bottom: 30px;
    margin-left: 10px;
    text-align: left;

}
#mode_md {
    background-color: #eaeaea;
    margin: 10px 10px 5px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-radius: 5px;
    display: flex;
}
#word {
    font-size: 20px;
    margin-left: 10px;
}
#word1 {
    font-size: 20px;
    margin-left: 10px;
}
#word2_md {
    font-size: 15px;
    margin-left: 10px;
}
#word3_md {
    font-size: 20px;
    color: white;
}
#save_md {
    background-color: rgb(50, 50, 50);
    margin: 25px 10px 5px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    text-align: center;
}

  input[type="radio"] {
    height: 1.2rem;
    width: 1.2rem;
    margin-left: 10px;
    background: none;
}

#accent {
    accent-color: black;
    background-color: rgb(50, 50, 50);
}
/* Header */
#backButtonMD {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleMD {
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_MD {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerMD {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-bottom: 10px;
}