#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
}

/* Header */
#backButtonGP {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleGP{
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_GP {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerGP {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-bottom: 10px;
}

.sharerButton {
    border: 0px solid blue;
    margin: 2%;
    border-radius: 5px;
    padding: 1%;
    background-color: white;
    box-shadow: 0px 0px 5px grey;
    width: 95.5vw;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    color: black;

    animation: backwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) backwards;
}

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0px 0px 5px grey;
    }
}

.imgInfoDiv {
    display: flex;
    margin-left: 1%;
}

.pfp_gp {
    margin: 0.5%;
    margin-right: 1.5%;
    border-radius: 5px;
    overflow: hidden;
    height: 90px;
    width: 90px;
    background-color: white;
    object-fit: cover;
}

.infoDiv > p {
    margin: 0;
}

.infoDiv {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-left: 0.5%;
}

.addr {
    margin: 1%;
}

#previousButton {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitle {
    display: flex;
    padding-left: 2.5%;
    top: 50%;
    font-size: 1.25em;
}

#header {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
}