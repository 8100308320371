#backArrowPlaceHolder {
    position: relative;
    width: 100px;
    height: undefined;
    aspect-ratio: 1/1;
}

#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
    overflow-x: hidden;
}

#signUpBackButton {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#signUpBackPic {
    position: relative;
    width: 45px;
    height: 45px;
    height: undefined;
    aspect-ratio: 1/1;
}

#potLogo {
    position: relative;
    width: 100px;
    height: undefined;
    aspect-ratio: 1/1;
}

#signUpHeader {
    position: absolute;
    font-size: 4.5vh;
    font-weight: bold;
    top: 19vh;
    left: 5vw;
    right: auto;
    margin-bottom: 5vh;
}

#signUpNameBox {
    position: absolute;
    top: 27vh;
    margin-left: 5vw;
    margin-right: 5vw;
    left: auto;
    right: auto;
    width: 84vw;
    height: 6vh;
    background-color: rgb(211, 210, 210);
    border-radius: 2vw;
    border: none;
    font-size: 1.8vh;
    margin-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

#signUpNameError {
    position: absolute;
    top: 34vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#loginAgeBox {
    position: absolute;
    top: 37vh;
    margin-left: 5vw;
    margin-right: 5vw;
    left: auto;
    right: auto;
    width: 84vw;
    height: 6vh;
    background-color: rgb(211, 210, 210);
    border-radius: 2vw;
    border: none;
    font-size: 1.8vh;
    margin-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

#signUpAgeError {
    position: absolute;
    top: 44vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#signUpEmailBox {
    position: absolute;
    top: 47vh;
    margin-left: 5vw;
    margin-right: 5vw;
    left: auto;
    right: auto;
    width: 84vw;
    height: 6vh;
    background-color: rgb(211, 210, 210);
    border-radius: 2vw;
    border: none;
    font-size: 1.8vh;
    margin-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

#signUpEmailError {
    position: absolute;
    top: 54vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#signUpPasswordError {
    position: absolute;
    top: 54vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#signUpPasswordBox {
    position: absolute;
    top: 57vh;
    margin-left: 5vw;
    margin-right: 5vw;
    left: auto;
    right: auto;
    width: 84vw;
    height: 6vh;
    background-color: rgb(211, 210, 210);
    border-radius: 2vw;
    border: none;
    font-size: 1.8vh;
    margin-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

#signUpPasswordError {
    position: absolute;
    top: 64vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#signUpPasswordConfirmBox {
    position: absolute;
    top: 67vh;
    margin-left: 5vw;
    margin-right: 5vw;
    left: auto;
    right: auto;
    width: 84vw;
    height: 6vh;
    background-color: rgb(211, 210, 210);
    border-radius: 2vw;
    border: none;
    font-size: 1.8vh;
    margin-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

#signUpPasswordConfirmError {
    position: absolute;
    top: 74vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#termsDescription {
    position: absolute;
    top: 87vh;
    left: auto;
    right: auto;
    margin-left: 10vw;
    margin-right: 10vw;
    font-size: 1.8vh;
}

#loginNextButton {
    position: absolute;
    top: 78vh;
    left: 5vw;
    right: auto;
    width: 90vw;
    height: 6vh;
    border-radius: 2vw;
    background-color: rgb(42, 42, 42);
    color: white;
    font-size: 2.8vh;
}

#continueOption {
    position: relative;
    top: 82vh;
    text-align: center;
    font-size: 2.1vh;
    color: grey;
    /* position: relative;
    width: 90vw;
    height: 10vh;
    margin-bottom: 3vh; */
}

#signUpNoAccount {
    position: relative;
    top: 93vh;
    text-align: center;
    color: black;
    font-size: 1.8vh;
}

#signUpNoAccountLogin {
    font-weight: bold;
    display: inline-block;
}

#googleButton2 {
    position: relative;
    top: 85vh;
    left: 28%;
    width: fit-content;
}

/*Google Button*/
.login-with-google-btn2 {
    align-items: center;
    justify-content: center;

    transition: background-color 0.3s, box-shadow 0.3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 40px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #757575;
    /* font-size: 14px; */
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
  
    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    }
  
    &:active {
      background-color: #eeeeee;
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
        0 0 0 3px #c8dafc;
    }
  
    &:disabled {
      filter: grayscale(100%);
      background-color: #ebebeb;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }