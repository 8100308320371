#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
    overflow-x: hidden;
}

#headerStrain {
    position: sticky;
    top: 0;
    height: 15vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    background-color: #fafafa;
    /* outline: auto; */
}

#headerStrain > button {
    border: 0px solid blue;
    background-color: inherit;
    color: blue;
    font-weight: bold;
    font-size: medium;
    transform: translateX(3vw);
    /* outline: auto; */
}

.strainDiv {
    border: 0px solid blue;
    margin: 2%;
    border-radius: 5px;
    padding: 1%;
    background-color: white;
    box-shadow: 0px 0px 5px grey;
    width: 93.5vw;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    color: black;

    animation: backwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) backwards;
}

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0px 0px 5px grey;
    }
}

#pfp_ss {
    margin: 0.5%;
    margin-right: 1.5%;
    border-radius: 5px;
    overflow: hidden;
    height: 90px;
    width: 90px;
    object-fit: cover;
    background-color: white;
}
#strainPic_ss {
    margin: 0.5%;
    margin-right: 1.5%;
    border-radius: 5px;
    overflow: hidden;
    height: 80px;
    width: 80px;
    object-fit: cover;
    background-color: white;
}

.imgInfo {
    display: flex;
    margin-left: 1%;
}

#infoDiv {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-left: 0.5%;
}

#addrInfo, #distInfo, #rating {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 2vw;
    margin-left: 2vw;
}

.price {
    display: flex;
    justify-content: space-between;
    margin: 2%;
    align-items: center;
}

.add {
    width: 22vw;
    height: 7vw;
    background-color: #323232;
    color: #fafafa;
    border: 0px solid #048474;
    border-radius: 5px;
}

.remove {
    width: 22vw;
    height: 7vw;
    background-color: #f52e37;
    color: #fafafa;
    border: 0px solid #048474;
    border-radius: 5px;
}

p, b {
    margin: 0;
}

#nextDiv {
    bottom: 0;
    position: sticky;
    display: flex;
    justify-content: space-evenly;
    background-color: #fafafa;
    height: 13.5vw;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
}

#nextDiv > button {
    background-color: #323232;
    color: #fafafa;
    border: 0px solid #048474;
    border-radius: 5px;
    width: 45%;
}

#total {
    padding-left: 2%;
    width: 45%;
    text-align: left;
    border: 3px solid #323232;
    border-radius: 5px;
    background-color: white;
}

/* div {
    outline: auto;
} */
