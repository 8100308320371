.map-responsive2{

    overflow:hidden;

    padding-bottom:2vh;

    position:relative;

    height:65vh;

}

#msg {
    animation: forwards;
}

.map-responsive2 iframe{
    left:0;
    top:0;
    height:65vh;
    width:100%;
    position:absolute;

}

#whiteBox {
    position: absolute;
    background-color: white;
    border-radius: 2vw;
    border-bottom-left-radius: 5vw;
    border-bottom-right-radius: 5vw;
    top: 18.5vh;
    left: 25vw;
    right: 25vw;
    width: 50vw;
    height: 21vh;
}

#locationDescriptionHeader {
    position: absolute;
    top: 20vh;
    left: 30vw;
    right: 30vw;
    font-weight: bold;
    font-size: 2.5vh;
}

#locationDescription {
    position: absolute;
    top: 23.5vh;
    left: 30vw;
    right: 30vw;
    font-size: 2vh;
}

#locationTextBox {
    position: absolute;
    top: 29vh;
    left: 30vw;
    right: 30vw;
    width: 36vw;
    height: 4.5vh;
    border-radius: 1vw;
    padding-left: 2vw;
    padding-right: 2vw;
    border-color: black;
    border-width: 0.4vw;
}

#cancelLocationButton {
    position: absolute;
    background-color: white;
    color: blue;
    font-weight: bold;
    top: 35.5vh;
    left: 25vw;
    right: 55vw;
    width: 25vw;
    height: 4.5vh;
    border-top-color: black;
    border-right-color: black;
    border-left-width: 0;
    border-right-width: 0.3vw;
    border-bottom-width: 0;
    border-bottom-left-radius: 2vw;
}

#changeLocationButton {
    position: absolute;
    background-color: white;
    color: blue;
    font-weight: bold;
    top: 35.5vh;
    left: 50vw;
    right: 30vw;
    width: 25vw;
    height: 4.5vh;
    border-top-color: black;
    border-left-color: black;
    border-right-width: 0;
    border-left-width: 0.3vw;
    border-bottom-width: 0;
    border-bottom-right-radius: 2vw; 
    
}

#currentLocationInfo {
    font-size: 2.8vh;
    position: relative;
    text-align: center;
    margin-bottom: 3vh;
    margin-left: 3vw;
    margin-right: 3vw;
}

#locationPrompt {
    font-size: 2.8vh;
    position: relative;
    text-align: center;
    margin-bottom: 4.5vh;
}

#changeLocation {
    color: white;
    background-color: #313030;
    width: 90vw;
    height: 7vh;
    position: relative;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 2.3vh;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 3vh;
    border: none;
}

#onlineButton {
    color: white;
    background-color: #313030;
    width: 90vw;
    height: 7vh;
    position:relative;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5vh;
    margin-bottom: 5vw;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 3vh;
    border: none;
}

#findingSharee {
    font-size: 2.8vh;
    position: relative;
    text-align: center;
}

#offlineButton {
    color: white;
    background-color: #313030;
    width: 90vw;
    height: 7vh;
    position:relative;
    margin-left: 5vw;
    margin-right: 5vw;
    top: 5vh;
    margin-bottom: 5vw;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 3vh;
    border: none;
}

#shareeNewMap {
    overflow:hidden;

    padding-bottom:4vh;

    position:relative;

    height:75vh;
    width: 100vw;
}
#request_ms {
    height: 50%;
    width: 90%;
    position: fixed;
    /* outline: auto; */
    /* top: 15%; */
    bottom: 40%;
    left: 5%;
    background-color: white;
    text-align: center;
    
}
#sharer {
    display: flex;
    /* outline: auto; */
}
#shinfo {
    text-align: left;
    margin-left: 10px;
    font-size: 10pt;
    line-height: 25px;
    padding-block: 5px;
    /* outline: auto; */
}
#orderinfo {
    text-align: left;
    padding-left: 10px;
    font-size: 10pt;
    line-height: 20px;
    padding-block: 5px;
    /* outline: auto; */
    background-color: white;
}
#countdown {
    text-align: center;
    color: rgb(20, 88, 192);
    /* outline: auto; */
    margin-left: -8px;
}
#bottom_sh {
    display: flex;
    padding-block: 10px;
    /* position: absolute; */
    /* outline: auto; */
    background-color: white;
    margin-left: -8px;
    margin-right: 5px;
}
#reqButton {
    color: white;
    background-color: #313030;
    width: 70%;
    height: 5vh;
    position:relative;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 11pt;
    /* margin-right: 30px; */
    margin-left: 5px;
    border: none;
    
}
#reqdesc {
    margin-inline: 5px;
    padding-bottom: 10px;
    text-align: center;
}

#orderID {
    font-size: 2.8vh;
    position: relative;
    text-align: center;
}

#sharerNavBar {
    position: absolute;
    top: 5vh;
    left: 3vw;
    right: auto;
}