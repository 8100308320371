#orderHistory {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Header */
#backButtonOH {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#pageTitleOH {
    display: flex;
    top: 50%;
    font-size: 1.25em;
    margin-top: 15px;
}
#backPic_OH {
    width: 25px;
    height: 25px;
    margin-left: 0px;
    margin-top: 15px;
}

#headerOH {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 2.5%;
    padding-bottom: 10px;
}

#phoneFrame {
    position: relative;
    height: 89.5vh;
    background-color: #fafafa;
    overflow: scroll;
    overflow-x: hidden;
}
#word {
    margin-top: 5px;
    font-size: 16px;
    margin-left: 20px;
}

#word1 {
    font-size: 12px;
    font-weight: bold;
    /* margin-bottom: 5px; */
}

#word2  {
    font-size: 12px;
    /* margin-top: 5px; */
    font-weight: bold;
}

#question1 {
    font-size: 12px;
    font-weight: bold;
    /* margin-bottom: 5px; */
    margin-left: -50px;
}

#answer1 {
    font-size: 12px;
    font-weight: 500;
    /* margin-bottom: 5px; */
    color: rgb(51, 51, 51);
    margin-left: -50px;
}

#question2 {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

#answer2 {
    font-size: 12px;
    /* margin-top: 5px; */
    font-weight: bold;
}



#mode {
    display: flex;
    margin: 10px 10px 5px 10px;
    /* padding-top: 10px; */
    padding-bottom: 10px;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0px 0px 5px grey;
    height: 25vw;
    overflow: hidden;

    animation: backwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) backwards;
}
#col1 {
    margin-left: 10px;
    /* outline: auto; */
}
#col2 {
    margin: 10px 10px 5px 0px;
}

.infoGroup {
    width: 25vw;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* outline: auto; */
}

#img_OH {
    width: 80px;
    height: 80px;
    aspect-ratio: 1;
    margin-left: 10px;
    margin-top: 7px;
    border-radius: 5px;
    object-fit: cover;
}

.strainGroup {
    width: 35vw;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow: scroll;
    height: 100%;
    /* outline: auto; */
}

/* animation */
@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0px 0px 5px grey;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0px 0px 5px grey;
    }
}