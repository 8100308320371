#landingPageTitle { 
    white-space: nowrap;
    margin-bottom: 25vw;
}

#landingHeading {
    color: black;
    position: relative;
    top: 10vw;
    padding-right: 5vw;
    font-size: 13vw;
    font-weight: bold;
    display: inline;
    white-space: nowrap;
}

#potLogo {
    width: 20vw;
    height: 20vw;
}

#descriptionLabel {
    position: relative;
    text-align: center;
    margin-bottom: 3vw;
    font-size: 7vw;
    font-weight: bold;
    margin-left: 3vw;
}

#redirectInstructions {
    position: relative;
    font-size: 5vw;
    color: black;
    font-weight: bold;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 20vw;
}

#shakingHandsImage {
    position: relative;
    text-align: center;
    align-self: center;
    left: 10vw;
    height: 35vw;
    width: 70vw;
    margin-bottom: 20vw;
    left: 15%;
    object-fit: cover;
}

#imgDiv {
    position: relative;
    top: 13vw;
    display: inline;
}

#collapsibleDescription {
    position: relative;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 5vw;
}

#playStoreLink {
    position: relative;
    text-align: center;
    align-self: center;
}