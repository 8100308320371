#phoneFrameOC {
    position: relative;
    height: 89.5vh;
    background-color: white;
    overflow: scroll;
    overflow-x: hidden;
}

#headerOC {
    position: sticky;
    top: 0;
    height: 15vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    background-color: white;
    /* outline: auto; */
}

#headerOC > button {
    background-color: inherit;
    border: 0px solid red;
    color: blue;
    font-size: medium;
    font-weight: bold;
    transform: translateX(-8vw);
}

#confirmBlurb {
    font-size: small;
    margin: 3%;
    margin-top: 2%;
}

#phoneInput {
    margin: 3%;
}

#phoneInput > p {
    color: grey;
    font-weight: 200;
    font-size: medium;
}

#phoneInput > input {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid black;
    caret-color: black;
    font-size: 1.25em;
    width: 90%;
    height: 1em;
}

#delChoice {
    margin: 3%;
}

#delChoice > label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 12vw;
    margin: 1vw;
    width: 45vw;
    background-color: #e7e6e8;
    /* outline: auto; */
}

#pickupType {
    margin: 3%;
}

#feeStatement {
    margin: 3%;
}

#feeStatement > b, #delChoice > p {
    font-weight: 500;
}

#request {
    background-color: #323232;
    color: white;
    border: 0px solid blue;
    border-radius: 5px;
    bottom: 0;
    position: sticky;
    display: flex;
    height: 13.5vw;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    align-items: center;
    justify-content: center;
    width: 95.5vw;
    left: 2.25vw;
    font-size: large;
}

/* #payPalBox {
    position: absolute;
    margin-left: 10vw;
    margin-right: auto;
    width: 70vw;
    top: 20vh;
    background-color: white;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 3vw;
    padding-top: 5vw;
    border-radius: 3vw;
    border: 0.25vw solid black;
} */

.popup-overlay {
    background: rgba(0,0,0,0.5);
}
#countdown3 {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #323232;
    border-radius: 7.5px;
    background-color: #323232;
    margin-top: 4%;
    margin-left: 4%;
    margin-right: 4%;
    height: 50px;
    width: 92vw;
    text-decoration: none;
    color: white;
    font-size: 1.25em;
    outline-color: red;
    
}