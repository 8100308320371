#passwordBackDiv {
    position: absolute;
    top: 2vh;
    left: 2vw;
    right: auto;
    margin-bottom: 3vh;
}

#passwordBackButton {
    display: flex;
    border: none;
    appearance: none;
    background-color: inherit;
}

#passwordBackPic {
    width: 10vw;
    height: 4vh;
    object-fit: cover;
}

#potLogoFP {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 3vh;
    object-fit: cover;
}

#FPHeader {
    position: absolute;
    font-size: 3.5vh;
    font-weight: bold;
    top: 19vh;
    left: 5vw;
    right: auto;
    margin-bottom: 5vh;
}

#FPDescription {
    position: absolute;
    font-size: 1.8vh;
    font-style: italic;
    top: 25vh;
    left: 5vw;
    margin-right: 5vw;
    right: auto;
}

#FPBox {
    position: relative;
    top: 34vh;
    margin-left: 5vw;
    margin-right: 5vw;
    left: auto;
    right: auto;
    width: 84vw;
    height: 6vh;
    background-color: rgb(211, 210, 210);
    border-radius: 2vw;
    border: none;
    font-size: 1.8vh;
    margin-bottom: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
}

#FPEmailError {
    position: absolute;
    top: 41vh;
    margin-left: 10vw;
    text-align: center;
    color: red;
}

#FPSubmitButton {
    position: absolute;
    top: 45vh;
    left: 5vw;
    right: auto;
    width: 90vw;
    height: 6vh;
    border-radius: 2vw;
    background-color: rgb(42, 42, 42);
    color: white;
    font-size: 2.8vh;
}